/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, FormControlLabel, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { setDefaultHyphenDateRange, sortByProperty } from '../../../utils/helpers';
import {
    listTypes,
    localStorageKey,
    listTypesId,
    endpoint,
    responseCode,
    snackbarVariant,
    snackbarMsg,
    waterpipesAll,
    snackbarDefaultValue,
    communicatorsChannel,
} from '../../../utils/constants';
import DateRange from '../../DateRange/DateRange';
import CommunicatorsList from '../../CommunicatorsList/CommunicatorsList';
import WaterpipeList from '../../WaterpipeList/WaterpipeList';
import TitleHeader from '../../TitleHeader/TitleHeader';
import FavoriteReportName from '../../FavoriteReports/FavoriteReportName/FavoriteReportName';
import FavoriteReportDescription from '../../FavoriteReports/FavoriteReportDescription/FavoriteReportDescription';
import FavoriteReportsList from '../../FavoriteReports/FavoriteReportsList/FavoriteReportsList';
import { axiosInstance } from '../../../utils/axiosInstance';
import Snackbar from '../../Snackbar/Snackbar';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import theme from '../../../themes/theme';
import TextLink from '../../TextLink/TextLink';
import CommunicatorChannelList from '../../CommunicatorChannelList/CommunicatorChannelList';

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    formContent: {
        width: '80%',
    },
    container: {
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
    },
    selectsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    buttonsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        margin: theme.margins.button.all,
        width: 220,
    },
    favoriteContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: '5rem',
    },
    favoriteOptionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    actionButtonsContainer: {
        width: '100%',
        marginTop: '2.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    actionButton: {
        margin: theme.margins.button.all,
        width: 150,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.75rem',
            lineHeight: 1,
            marginTop: '0.25rem',
            userSelect: 'none',
        },
        '& .MuiFormControlLabel-label': {
            marginTop: -4,
        },
    },
    warnButton: {
        marginTop: 200,
        color: 'red',
        backgroundImage: 'linear-gradient(#FF0000, #FF0000)',
    },
};

const TestListForm = ({
    classes: {
        main,
        formContent,
        container,
        selectsContainer,
        buttonsContainer,
        button,
        favoriteContainer,
        favoriteOptionsContainer,
        actionButtonsContainer,
        actionButton,
        checkboxContainer,
    },
}) => {
    const [isCommunicatorsListEmpty, setIsCommunicatorsListEmpty] = useState(true);
    const [isFocusOnCommunicatorsList, setIsFocusOnCommunicatorsList] = useState(false);
    const [isAnyError, setIsAnyError] = useState(false);
    const [isAnyErrorDate, setIsAnyErrorDate] = useState(false);
    const [waterpipeId, setWaterpipeId] = useState(null);
    const [waterpipeSign, setWaterpipeSign] = useState(null);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [numbers, setNumbers] = useState(null);
    const [channel, setChannel] = useState(communicatorsChannel.ONE);
    const [isFavoriteOptionsExpanded, setIsFavoriteOptionsExpanded] = useState(false);
    const [favoriteReportName, setFavoriteReportName] = useState('');
    const [favoriteReportDesc, setFavoriteReportDesc] = useState('');
    const [favoriteReports, setFavoriteReports] = useState([]);
    const [reading, setReading] = useState(false);
    const [selectedFavoriteReport, setSelectedFavoriteReport] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [snackbarData, setSnackbarData] = useState(snackbarDefaultValue);
    const [clearReport, setClearReport] = useState(false);
    const [changeData, setChangeData] = useState(false);
    const [fetchWaterpipesError, setFetchWaterpipesError] = useState('');

    const handleOpenNewTabOrderedList = () => window.open(`${window.location.origin}/#/test-ordered-list`);

    useEffect(() => {
        const localStorageData = JSON.parse(localStorage.getItem(localStorageKey.GENERATED_TEST_LIST_DATA));
        if (localStorageData) {
            setNumbers(localStorageData.numbers);
            setWaterpipeId(localStorageData.waterpipeId);
        } else {
            setNumbers('');
            setWaterpipeId(0);
        }

        getFavoriteReports();
    }, []);

    useEffect(() => {
        if (isCommunicatorsListEmpty) {
            setIsAnyError(true);
        }
    }, [isCommunicatorsListEmpty]);

    useEffect(() => {
        if (fetchWaterpipesError) {
            setSnackbarData({
                isSnackbarOpen: true,
                message: snackbarMsg.ERROR_CONNECTION,
                errorInfo: '',
                variant: snackbarVariant.ERROR,
            });
        }
    }, [fetchWaterpipesError]);

    const cancelFavoriteMenu = () => {
        setIsFavoriteOptionsExpanded(false);
        setFavoriteReportName('');
        setFavoriteReportDesc('');
    };

    const managePanel = () => {
        if (isFavoriteOptionsExpanded) {
            cancelFavoriteMenu();
        } else {
            setIsFavoriteOptionsExpanded(true);
        }
    };

    const redirectToFavoritesPage = () => {
        if (typeof window !== 'undefined') {
            window.open(`${window.location.origin}/#/list-templates/${listTypesId.TEST_LIST}`);
        }
    };

    const saveFavoriteReport = () => {
        const listData = {
            waterpipe_id: Number(waterpipeId),
            numbers,
            channel,
            name: favoriteReportName,
            description: favoriteReportDesc,
            template_type: listTypesId.TEST_LIST,
        };

        axiosInstance
            .post(endpoint.LIST_TEMPLATE_CREATE, listData)
            .then(res => {
                if (res.status === responseCode.SUCCESS) {
                    setSnackbarData({
                        isSnackbarOpen: true,
                        message: snackbarMsg.LIST_TEMPLATE_HAS_BEEN_ADDED,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    });
                    getFavoriteReports();
                    clearForm();
                }
            })
            .catch(err => {
                console.error('Error while adding new list template', err);
                setSnackbarData({
                    isSnackbarOpen: true,
                    message: snackbarMsg.ERROR_CONNECTION,
                    errorInfo: '',
                    variant: snackbarVariant.ERROR,
                });
            });
    };

    const getFavoriteReports = () => {
        const listTemplatesColumnState = JSON.parse(
            localStorage.getItem(localStorageKey.LIST_TEMPLATES_COLUMN_STATE)
        );

        axiosInstance
            .get(`${endpoint.LIST_TEMPLATE_INDEX}?templateType=${listTypesId.TEST_LIST}`)
            .then(res => {
                let listTemplates = res.data.map(result => ({
                    id: result.id,
                    waterpipeId: result.waterpipe_id,
                    numbers: result.numbers,
                    name: result.name,
                    description: result.description,
                    createdAt: result.created_at,
                    channel: result.channel,
                }));

                if (listTemplatesColumnState?.length) {
                    let waterpipesList = JSON.parse(localStorage.getItem(localStorageKey.WATERPIPES_LIST));

                    if (!waterpipesList.filter(e => e.name === waterpipesAll.name).length) {
                        waterpipesList = [...waterpipesList, waterpipesAll];
                    }

                    const sortOption = listTemplatesColumnState.filter(e => e.sort)[0];

                    if (sortOption) {
                        listTemplates = sortByProperty(
                            listTemplates.map(template => ({
                                ...template,
                                description: template.description === null ? '' : template.description,
                                waterpipeSign: waterpipesList.filter(
                                    waterpipe => waterpipe.id === template.waterpipeId
                                )[0].sign,
                                waterpipeName: waterpipesList.filter(
                                    waterpipe => waterpipe.id === template.waterpipeId
                                )[0].name,
                            })),
                            sortOption.colId,
                            sortOption.sort
                        );
                    }
                }

                setFavoriteReports(listTemplates);
            })
            .catch(err => {
                console.error('Error while fetching data', err);
                setSnackbarData({
                    isSnackbarOpen: true,
                    message: snackbarMsg.ERROR_CONNECTION,
                    errorInfo: '',
                    variant: snackbarVariant.ERROR,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const showGenerateErrorSnackbar = () => {
        setSnackbarData({
            isSnackbarOpen: true,
            message: snackbarMsg.ERROR,
            errorInfo: snackbarMsg.TEST_REPORT_GENERATE_ERROR,
            variant: snackbarVariant.ERROR,
        });
    };

    const generateTestReport = () => {
        const listData = {
            waterpipeId: Number(waterpipeId),
            numbers,
            channel,
            dateFrom,
            dateTo,
            reading,
        };

        axiosInstance
            .post(endpoint.TEST_REPORT_V2_CREATE, listData)
            .then(res => {
                if (res?.data?.success) {
                    setSnackbarData({
                        isSnackbarOpen: true,
                        message: snackbarMsg.TEST_REPORT_GENERATE_SUCCESS,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    });
                } else {
                    showGenerateErrorSnackbar();
                }
            })
            .catch(showGenerateErrorSnackbar);
    };

    const loadFavoriteReport = data => {
        setWaterpipeId(data.waterpipeId);
        setNumbers(data.numbers);
        setChannel(data.channel);
        cancelFavoriteMenu();
        setClearReport(true);
        setChangeData(false);
    };

    useEffect(() => {
        if (clearReport && changeData) {
            setChangeData(false);
            setClearReport(false);
            setSelectedFavoriteReport(null);
        } else if (clearReport && !changeData) {
            setChangeData(true);
        }
    }, [waterpipeId, numbers, channel]);

    const clearForm = () => {
        const dates = setDefaultHyphenDateRange(30);
        setWaterpipeId(0);
        setNumbers(' ');
        setDateFrom(dates.amountOfDaysBefore);
        setDateTo(dates.present);
        setChannel(communicatorsChannel.ONE);
        setSelectedFavoriteReport(null);
        cancelFavoriteMenu();
    };

    const handleIsSnackbarOpen = () => {
        setSnackbarData(snackbarData => ({
            isSnackbarOpen: false,
            message: snackbarData.message,
            errorInfo: snackbarData.errorInfo,
            variant: snackbarData.variant,
        }));
    };

    const clearLocalStorage = () => {
        localStorage.clear();
        if (typeof window !== 'undefined') {
            window.open(`${window.location.origin}/#/login`, '_self');
        }
    };

    const isDataError = !!(isAnyError || isAnyErrorDate || fetchWaterpipesError || !numbers);

    return (
        <div className={main}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className={formContent}>
                    <TitleHeader title={'Zestawienie testowe'} />
                    <section className={container}>
                        <div className={selectsContainer}>
                            <WaterpipeList
                                listType={listTypes.TEST_LIST}
                                setWaterpipeId={setWaterpipeId}
                                setWaterpipeSign={setWaterpipeSign}
                                waterpipeId={waterpipeId}
                                setFetchError={setFetchWaterpipesError}
                            />
                            <CommunicatorChannelList setChannel={setChannel} channel={channel} />
                            <FormControlLabel
                                className={checkboxContainer}
                                control={
                                    <Checkbox
                                        checked={reading}
                                        onChange={(e, value) => setReading(value)}
                                        color="primary"
                                    />
                                }
                                label="ZD5/ZD6"
                                labelPlacement="top"
                            />
                            <DateRange
                                setIsAnyError={setIsAnyErrorDate}
                                setDateFrom={setDateFrom}
                                setDateTo={setDateTo}
                            />
                            <FavoriteReportsList
                                favoriteReports={favoriteReports}
                                loadFavoriteReport={loadFavoriteReport}
                                selectedFavoriteReport={selectedFavoriteReport}
                                setSelectedFavoriteReport={setSelectedFavoriteReport}
                            />
                        </div>

                        <CommunicatorsList
                            isFocusOnCommunicatorsList={isFocusOnCommunicatorsList}
                            setFocusOnCommunicatorsFieldType={setIsFocusOnCommunicatorsList}
                            setIsCommunicatorsListEmpty={setIsCommunicatorsListEmpty}
                            setIsAnyError={setIsAnyError}
                            setNumbers={setNumbers}
                            numbers={numbers}
                            multiline
                        />

                        <div className={buttonsContainer}>
                            <TextLink description={'Dodaj do ulubionych'} onClick={managePanel} />
                            <TextLink
                                description={'Zarządzaj ulubionymi'}
                                onClick={redirectToFavoritesPage}
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                className={button}
                                onClick={generateTestReport}
                                disabled={isDataError}>
                                ZAMÓW ZESTAWIENIE
                            </Button>

                            <TextLink
                                description={'Zamówione zestawienia'}
                                onClick={handleOpenNewTabOrderedList}
                            />

                            <TextLink description={'Wyczyść formularz'} onClick={clearForm} />
                        </div>

                        <div className={favoriteContainer}>
                            <Collapse
                                className={favoriteOptionsContainer}
                                in={isFavoriteOptionsExpanded}
                                timeout={500}>
                                <FavoriteReportName
                                    favoriteReportName={favoriteReportName}
                                    setFavoriteReportName={setFavoriteReportName}
                                />
                                <FavoriteReportDescription
                                    favoriteReportDesc={favoriteReportDesc}
                                    setFavoriteReportDesc={setFavoriteReportDesc}
                                    style={{
                                        marginTop: theme.margins.textField.top,
                                    }}
                                />
                                <div className={actionButtonsContainer}>
                                    <Button
                                        variant="contained"
                                        className={actionButton}
                                        onClick={cancelFavoriteMenu}>
                                        ANULUJ
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={actionButton}
                                        onClick={saveFavoriteReport}
                                        disabled={
                                            isAnyError ||
                                            isAnyErrorDate ||
                                            fetchWaterpipesError ||
                                            numbers.length < 8 ||
                                            !favoriteReportName
                                        }>
                                        ZAPISZ
                                    </Button>
                                </div>
                            </Collapse>
                            <TextLink
                                description={'Wyczyść dane użytkownika'}
                                onClick={clearLocalStorage}
                                style={styles.warnButton}
                            />
                            <Snackbar
                                snackbarData={snackbarData}
                                handleIsSnackbarOpen={handleIsSnackbarOpen}
                            />
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(TestListForm);
